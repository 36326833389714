import Cookies from "js-cookie";
import axios from "axios";

import router from "@/router/index";

import AuthService from "@/services/resources/AuthService";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
  actionGetUserLogged({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceMember.read("/meta");
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  actionUpdateAvatar({ commit }, avatar) {
    commit("auth/SET_AVATAR", avatar, { root: true });
  },

  actionEditProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceMember.postID(payload);
        if (response.success === true) {
          commit("auth/SET_PROFILE", payload, { root: true });
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  async actionFetchMe({ commit, dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.getters["auth/getMe"]) return resolve();
        const response = await serviceMember.read("/me");
        commit("auth/SET_ME", response, { root: true });
        commit("user/currentSite", response.current, { root: true });
        await dispatch("app/actionUnload", undefined, { root: true });
        resolve(response);
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  },

  //fazer login do OAuth 2
  loginRequest(context, payload) {
    var service = AuthService.build();
    return new Promise((resolve, reject) => {
      service
        .login(payload.username, payload.password, payload.captcha)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          Cookies.remove("auth_greennCourse");
          reject(err);
        });
    });
  },

  updateUserCustomFields: function ({ commit }) {
    commit("auth/userCustomFields", true, { root: true });
  },


  async actionLogin({ commit, dispatch }, payload) {
    const userType = payload.role == "student" ? 1 : 0;

    Cookies.remove("auth_greennCourse");
    Cookies.remove("userCustomFields");
    Cookies.remove("currentSite");
    Cookies.remove("themeClub");
    Cookies.remove("metasSite");
    Cookies.remove("certificateConfig");
    Cookies.remove("type_student");
    Cookies.set("type_student", userType);

    // Configurar as informações da cookie
    const cookieOptions = {
      expires: 365, // 1 ano de expiração
      path: "/",
    };

    // Definir o valor da cookie para cada atributo
    Cookies.set("auth_greennCourse", payload.Auth, cookieOptions);
    Cookies.set("access_level", payload.role, cookieOptions);
    Cookies.set(
      "userCustomFields",
      payload.custom_fields_filled,
      cookieOptions
    );
    
    commit("auth/authSuccess", payload.Auth, { root: true }),
    commit("auth/userCustomFields", payload.custom_fields_filled, {
      root: true,
    })
    await dispatch("app/actionUnload", undefined, { root: true })
    await dispatch("app/actionLoad", undefined, { root: true })
    await dispatch("app/actionLogged", undefined, { root: true })
  },

  async actionDeepNavigate({ commit, dispatch }, payload) {
    const userType = payload.role == "student" ? 1 : 0;

    const oldSiteId = JSON.parse(Cookies.get("currentSite") || '{}').currentSite?.id
    
    const isAdmin = payload.role === "owner" || payload.role === "admin"
    const isStudent = payload.role === "student";
    const isPRD = process.env.VUE_APP_TYPE === "PROD";
    const isDEV = process.env.VUE_APP_TYPE !== "DEV";
    const isLocal = window.location.href.startsWith("http://")
    const isAppDomain = window.location.href.startsWith("https://app.voompplay");
    const isSubDomain = window.location.href.startsWith(`https://${payload.subdomain}`);

    let newPath = '/home'

    if (payload.course_id) {
      newPath = `/curso/${payload.course_id}`;
    }

    let origin = null;

    if (!isLocal && payload.subdomain && !payload.domains?.length && !isSubDomain) {
      origin = `https://${payload.subdomain}.voompplay.${process.env.VUE_URL_DOMAIN}`;
    }
    
    if (!isLocal && payload.domains?.length) {
      let currentDomain = payload.domains.find((it) => it.domain == window.location.origin)
      let locatedDomain = currentDomain || payload.domains[0];
      origin = locatedDomain?.domain;
    }

    if (!origin) {
      origin = `https://app.voompplay.${process.env.VUE_URL_DOMAIN}`
    }

    if (isLocal) {
      origin = window.location.origin
    }

    const isSameOrigin = window.location.origin == origin
    const isSameSite = oldSiteId == payload.siteId

    if (isSameOrigin && (!isSameSite || !payload.siteId)) {
      await dispatch('actionLogin', payload);
    }

    if (isSameOrigin) {
      router.push({ path: newPath });
    } else {
      const resp = await axios.post(process.env.VUE_APP_API_HOST + `/member/auth`, { 
        auth: { payload }
      })
      newPath = newPath + `?rdr=${resp.data.string}`;
      window.location.replace(origin + newPath);
    }
  },

  async blogSelected({ commit, dispatch }, payload) {
    const userType = payload.role == "student" ? 1 : 0;

    Cookies.remove("auth_greennCourse");
    Cookies.remove("userCustomFields");
    Cookies.remove("currentSite");
    Cookies.remove("themeClub");
    Cookies.remove("metasSite");
    Cookies.remove("certificateConfig");
    Cookies.remove("type_student");
    Cookies.set("type_student", userType);

    // Configurar as informações da cookie
    const cookieOptions = {
      expires: 365, // 1 ano de expiração
      path: "/",
    };

    // Definir o valor da cookie para cada atributo
    Cookies.set("auth_greennCourse", payload.Auth, cookieOptions);
    Cookies.set("access_level", payload.role, cookieOptions);
    Cookies.set(
      "userCustomFields",
      payload.custom_fields_filled,
      cookieOptions
    );

    // Chamadas das mutações e ações
    commit("auth/authSuccess", payload.Auth, { root: true });
    commit("auth/userCustomFields", payload.custom_fields_filled, {
      root: true,
    });

    await dispatch("app/actionUnload", undefined, { root: true })
    await dispatch("app/actionLoad", undefined, { root: true })
    await dispatch("app/actionLogged", undefined, { root: true })


    // Redirecionamento com base no ambiente e função
    if (process.env.VUE_APP_TYPE === "DEV") {
      if (payload.course_id) {
        router.push({ path: `/curso/${payload.course_id}` });
      } else {
        router.push({ path: "/home" });
      }
    } else if (
      process.env.VUE_APP_TYPE === "PROD" &&
      (payload.role === "owner" || payload.role === "admin")
    ) {
      var domainTo = window.location.host;
      if (
        domainTo.search(`app.voompplay.${process.env.VUE_URL_DOMAIN}`) === -1
      ) {
        var auth = { payload };
        axios
          .post(process.env.VUE_APP_API_HOST + `/member/auth`, { auth })
          .then(function (resp) {
            window.location.href =
              `https://app.voompplay.${process.env.VUE_URL_DOMAIN}?rdr=` +
              resp.data.string;
          });
      } else {
        if (payload.course_id !== undefined && payload.course_id !== null) {
          window.location.href =
            `http://app.voompplay.${process.env.VUE_URL_DOMAIN}/curso/` +
            payload.course_id;
        } else {
          window.location.href = `https://app.voompplay.${process.env.VUE_URL_DOMAIN}/home`;
        }
      }
    } else if (
      payload &&
      payload.domains &&
      process.env.VUE_APP_TYPE === "PROD" &&
      payload.domains.length === 0 &&
      payload.role === "student"
    ) {
      const regex = new RegExp(
        `(voompplay.${process.env.VUE_URL_DOMAIN})`,
        "gi"
      );
      let result = regex.exec(window.location.host);
      if (
        result === null ||
        window.location.host === `app.voompplay.${process.env.VUE_URL_DOMAIN}`
      ) {
        let auth = { payload };
        axios
          .post(process.env.VUE_APP_API_HOST + `/member/auth`, { auth })
          .then(function (resp) {
            window.location.href =
              "https://" +
              payload.subdomain +
              `.voompplay.${process.env.VUE_URL_DOMAIN}?rdr=` +
              resp.data.string;
          });
      } else {
        if (payload.course_id !== undefined && payload.course_id !== null) {
          window.location.href =
            "https://" +
            payload.subdomain +
            `.voompplay.${process.env.VUE_URL_DOMAIN}/curso/` +
            payload.course_id;
        } else {
          window.location.href =
            "https://" +
            payload.subdomain +
            `.voompplay.${process.env.VUE_URL_DOMAIN}/home`;
        }
      }
    } else if (
      process.env.VUE_APP_TYPE === "PROD" &&
      payload.domains.length !== 0
    ) {
      const domains = payload.domains;
      let xxxDomains = domains.filter(function (e) {
        return e.domain === "https://" + window.location.host;
      });

      if (xxxDomains.length === 0) {
        let auth = { payload };
        axios
          .post(process.env.VUE_APP_API_HOST + `/member/auth`, { auth })
          .then(function (resp) {
            window.location.href =
              payload.domains[0].domain + "?rdr=" + resp.data.string;
          });
      } else {
        if (payload.course_id !== undefined && payload.course_id !== null) {
          window.location.href =
            xxxDomains[0].domain + "/curso/" + payload.course_id;
        } else {
          window.location.href = xxxDomains[0].domain + "/home";
        }
      }
    }

    // if (payload?.current === "/home") {
    //   router.go();
    // } else {
    //   setTimeout(() => {
    //     router.go();
    //   }, 500);
    // }
  },

  async actionLoginFromRDR({ dispatch }) {
    var queryString = window.location.search;

    if (!queryString) {
      return false
    }

    const urlParams = new URLSearchParams(queryString);
    const params = urlParams.get("rdr");
    const type = urlParams.get("type");

    if (!params) {
      return false;
    }

    window.history.replaceState({}, '', window.location.pathname)

    const url = process.env.VUE_APP_API_HOST + `/member/auth/` + params + "?type=" + type;
    
    const resp = await axios.get(url);
    await dispatch("actionLogin", resp.data.payload);
  },

  logoutRequest: ({ commit }, payload) => {
    console.log("deslogando");
    // Remove cookies
    Cookies.remove("auth_greennCourse");
    Cookies.remove("memberAuth");
    Cookies.remove("currentSite");
    Cookies.remove("themeClub");
    Cookies.remove("metasSite");
    Cookies.remove("certificateConfig");
    Cookies.remove("type_student");
    // Remove state
    commit("auth/authLogout", undefined, { root: true });
    commit("user/userLogout", undefined, { root: true });
    commit("auth/authFeedback", payload?.feedback, { root: true });
  },
};
