// mutations.js
export default {
  SET_REARRANGE(state) {
    state.rearrange = !state.rearrange;
  },

  SET_HOME_DATA(state, response) {
    state.homeData = response;
  },

  UPDATE_COURSES_LIST(state, payload) {
    state.allCourses = Array.from(payload);
  },

  SET_CATEGORIES_DATA(state, allCategories) {
    state.allCategories = allCategories;
  },

  SET_OPEN_ACCORDION_CATEGORIES(state, index) {
    state.openedAccordionCategories = index;
  },

  SHOW_MODAL_INFO(state, payload) {
    state.modalInfoCourse = payload;
  },

  SHOW_MODAL_BUY(state, payload) {
    state.modalBuyCourse = payload;
  },

  SHOW_MODAL_FIRST_ACCESS(state, payload) {
    state.modalFirstAccess = payload;
  },

  SHOW_MODAL_TERMS_OF_USE(state, payload) {
    state.modalTermsOfUse = payload;
  },

  SET_LOADING_MODULE(state, loadingModule) {
    state.loadingModule = loadingModule;
  },
};
