<template>
  <div class="menu-content">
    <template>
      <div class="menu-links">
        <router-link id="home" to="/home" class="menu-item">
          <IconSax name="home-2" primary />
          {{ $t("menu.home") }}
        </router-link>

        <router-link id="certificado" data-v="T" to="/certificates" class="menu-item" v-if="getExistConfigCert">
          <IconSax name="medal-star" primary />
          {{ $t("menu.certificado") }}
        </router-link>

        <router-link id="my-courses" to="/my-courses" class="menu-item">
          <IconSax name="video-play" primary />
          {{ $t("menu.logout.meus_cursos") }}
        </router-link>

        <router-link v-if="hasPermission && studentCantView && getSettings?.comment_review" id="comunidade"
          to="/comunidade" class="menu-item">
          <IconSax name="messages-2" primary />
          {{ $t("menu.comments") }}
        </router-link>

        <router-link v-if="hasPermission && studentCantView" id="membros" to="/members" class="menu-item">
          <IconSax name="people" primary />
          {{ $t("menu.membros") }}
        </router-link>

        <router-link v-if="hasPermission && studentCantView" id="assinatura" to="/assinatura" class="menu-item">
          <IconSax name="box-tick" primary />
          {{ $t("menu.combos") }}
        </router-link>

        <div @click="showModalUpload" id="media-management" class="menu-item cursor-pointer"
          v-if="hasPermission && studentCantView">
          <IconSax name="folder-open" primary />
          {{ $t("menu.media_management") }}
        </div>

        <router-link v-if="exercisesAllowed" id="exercises" to="/exercicios/fisica" class="menu-item">
          <IconSax name="ruler&pen" primary />
          {{ $t("menu.exercises") }}
        </router-link>

        <router-link id="essays" v-if="hasEssayAccess" to="/essays" class="menu-item">
          <IconSax name="clipboard-tick" primary />
          {{ $t("menu.essay") }}
        </router-link>

        <router-link v-if="studyScheduleAllowed" id="study-schedule" to="/cronograma" class="menu-item">
          <IconSax name="calendar" primary />
          {{ $t("menu.study_schedule") }}
        </router-link>

        <router-link v-if="intensivaoAllowed" id="study-schedule" to="/intensivao" class="menu-item">
          <IconSax name="calendar" primary />
          {{ $t("menu.intensivao") }}
        </router-link>

        <router-link v-if="evolucionalAllowed" id="evolucional" to="/evolucional" class="menu-item">
          <IconSax name="book-square" primary />
          {{ $t("menu.evolucional") }}
        </router-link>

        <SidebarMenuCustom index="1" />

        <SidebarMenuCustom index="2" />

        <SidebarMenuCustom index="3" />

        <SidebarMenuCustom index="4" />

        <a v-if="getSettings?.helper_data && !getSettings?.external_help" id="helper"
          :href="`https://reclamacao.voompcreators.${url_domain}/`" target="_blank" class="menu-item">
          <IconSax name="message-question" primary />
          {{ $t("menu.ajuda") }}
        </a>

        <a v-if="getSettings?.helper_data && getSettings?.external_help" id="helper"
          :href="getSettings?.external_help_link" target="_blank" class="menu-item">
          <IconSax name="message-question" primary />
          {{ $t("menu.ajuda") }}
        </a>
      </div>
    </template>

    <ModalUpload />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SidebarMenuCustom from "./SidebarMenuCustom.vue";
import ModalUpload from "@/components/ModalUpload.vue";
import moment from "moment";

export default {
  components: {
    SidebarMenuCustom,
    ModalUpload,
  },
  data() {
    return {
      sub_menu: false,
      sub_menu2: false,
      item: null,
      top: 0,
      bottom: 0,
      commentReview: true,
      customHelper: false,
      adm_voomp_url: process.env.VUE_GREENN_ADM_HOST,
      url_domain: process.env.VUE_URL_DOMAIN,
      moderationHelper: true,
      permission: false,
      color: "red",
      fimMenu: 300,
      fimSubmenu: 0,
      client: {
        width: 0,
      },
      existConfigCert: true,
    };
  },
  methods: {
    showModalUpload() {
      this.$root.$emit("uploadWithoutSelect", { showSelectedMedia: false });
      this.$root.$emit("bv::show::modal", "modal-upload");
    },
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
      getTheme: "config/getTheme",
      getSettings: "config/getSettings",
      getModuleViewMode: "config/getModuleViewMode",
      getCategoryViewMode: "config/getCategoryViewMode",
      getMovieCoverMode: "config/getMovieCoverMode",
      getHideDisabledCourses: "config/getHideDisabledCourses",
      getIsMobile: "layout/getIsMobile",
      getModeCarousel: "config/getModeCarousel",
      getShowRating: "config/getShowRating",
      getIsLoading: "config/getIsLoading",
      getAllowRequest: "config/getAllowRequest",
      getHomeData: "home/getHomeData",
      getAllCategories: "home/getAllCategories",
      hasEssayAccess: "user/hasEssayPermission",
      hasPermission: "user/hasPermission",
      getExistConfigCert: "user/getExistConfigCert",
      studentCantView: "user/studentCantView",
    }),
    exercisesAllowed() {
      if (
        this.$store.getters["user/metasSite"] &&
        this.$store.getters["user/metasSite"].exercises_allowed
      ) {
        return this.$store.getters["user/metasSite"].exercises_allowed === "1";
      } else return null;
    },
    studyScheduleAllowed() {
      if (
        this.$store.getters["user/metasSite"] &&
        this.$store.getters["user/metasSite"].study_schedule_allowed
      ) {
        return this.$store.getters["user/metasSite"].study_schedule_allowed === "1";
      } else return null;
    },
    intensivaoAllowed() {
      const currentyDate = moment((new Date()), 'Y-m-d')

      return currentyDate.isBetween('2024-09-30', '2024-11-11') && this.studyScheduleAllowed
    },
    evolucionalAllowed() {
      if (
        this.$store.getters["user/metasSite"] &&
        this.$store.getters["user/metasSite"].evolucional_allowed
      ) {
        return this.$store.getters["user/metasSite"].evolucional_allowed === "1";
      } else return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton-menu-item {
  height: 20px;
  width: 140px;
  margin: 0.625rem 0rem;
}

.menu {
  &-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
  }

  &-item {
    @include flex-row;
    cursor: pointer;
    width: 100%;
    padding: 0.625rem 0rem;
    color: var(--fontcolor);
    align-items: center;
    gap: 1rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    transition: color 0.3s ease;

    ::v-deep svg {
      width: 1.25rem;
      height: 1.25rem;

      path {
        fill: var(--maincolor);
      }
    }

    &.router-link-active {
      color: var(--maincolor);
    }

    &:hover {
      color: var(--maincolor);
    }
  }
}
</style>
