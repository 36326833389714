<template>
    <b-modal id="my-issuance-certificate" hide-footer>
      <template #modal-header="{ close }">
        <div></div>
        <div class="my-issuance-certificate__close" @click="close">
          <IconSax name="close-circle" type="outline" />
        </div>
      </template>
      <div class="my-issuance-certificate">
        <div class="my-issuance-certificate__title">
          {{ $t('modal_issuance_certificate.title') }}
        </div>
        <div class="my-issuance-certificate__percent">
          {{ $t('modal_issuance_certificate.percent') }}
        </div>
        <div class="modalCertificate__action">
            <ButtonPlay
                type="normal"
                class="animationPulse modalCertificate__action--button"
                @click="issuanceCertify"
                >
                <IconSax name="medal-star" type="outline" />
                <span>{{ $t('modal_issuance_certificate.action') }}</span>
            </ButtonPlay>
        </div>
        <div class="modalCertificate__action">
            <ButtonPlay
                type="outline"
                @click="closeModal"
                >
                <span>{{ $t('modal_issuance_certificate.cancel') }}</span>
            </ButtonPlay>
        </div>
      </div>
    </b-modal>
  </template>
  <script>
  import { mapGetters } from "vuex";
  export default {
    components: {},
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters({
        getCurrentSite: "user/currentSite"
      })
    },
    methods: {
      closeModal(){
        this.$root.$emit("bv::hide::modal", "my-issuance-certificate");
      },
      issuanceCertify(){
        this.$root.$emit("issuanceCertify");
        this.closeModal();
      }
    },
  };
  </script>
  
  <style lang="scss">

  .modalCertificate__action button{
    width: 180px;
  }
  #my-issuance-certificate {
    .modal-header {
      border: none;
      padding: 16px 15px 0px 15px !important;
    }
    .modal-content {
      padding: 0px !important;
      min-height: 430px;
      max-width: 430px;
      background: var(--backgroundcolor) !important;
      border-radius: 8px;
    }
    .modal-body {
      padding: 0px 0;
    }
  }
  </style>
  
  <style lang="scss" scoped>
  .my-issuance-certificate  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 40px 51px;
    text-align: center;
    font: var(--body-regular-16);
    color: var(--opposite-color);
  
    &__close {
      cursor: pointer;
  
      &:hover {
        opacity: 0.7;
      }
  
      ::v-deep {
        svg {
          width: 24px;
          height: 24px;
          path {
            fill: var(--fontcolor);
          }
        }
      }
    }
  
    &__title {
      font: var(--body-bold-22);
    }
  
    &__action {
      font: var(--body-semibold-12);
  
      &--button {
        cursor: pointer;
  
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  </style>
  