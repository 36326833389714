import Vue from "vue";
import "@/utils/globals"
import "./diretivas/closable";
import vClickOutside from 'v-click-outside'
import App from "./App.vue";
import i18n from "./i18n";
import BootstrapVue from "bootstrap-vue";
import VueCodeHighlight from "vue-code-highlight";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VeeValidate from "vee-validate";
import VueSweetalert2 from "vue-sweetalert2";
import { Validator } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueTheMask from "vue-the-mask";
import StarRating from "vue-star-rating";
import components from "./components/index";
import ToggleButton from "vue-js-toggle-button";
import Snackbar from "node-snackbar";
import ModalNovoCertificado from "@/components/ModalNovoCertificado";
import ModalCertificateAvailable from "@/components/ModalCertificateAvailable";
import ModalIssuanceCertificate from "@/components/ModalIssuanceCertificate";
import Blur from "@/components/Blur";
import Intro from "@/components/Intro";
import VueIntro from "vue-introjs";
import "intro.js/introjs.css";
import VueObserveVisibility from "vue-observe-visibility";
import vueVimeoPlayer from "vue-vimeo-player";
import { func } from "@/services/libs/convertColor.js";
import Button from "@/components/base/Button";
import BaseModal from "@/components/base/BaseModal";
import Modal from "@/components/base/Modal";
import VoompTube from "@/components/players/voomptube/VoompTube.vue";
import ModalUpload from "@/components/ModalUpload.vue";
import ModalUploadPanda from "@/components/ModalUploadPanda.vue";
import "./assets/scss/multiple-select.scss";

import VueGtm from "@gtm-support/vue2-gtm";
import { getGtmConfig } from "./utils/gtm";
import IconSax from "@/components/common/IconSax.vue";
import Layout from "@/layout/Layout.vue";
import ButtonPlay from "@/components/common/ButtonPlay.vue";
import Divider from "@/components/common/Divider.vue";
import VueProgrammaticInvisibleGoogleRecaptcha from "vue-programmatic-invisible-google-recaptcha";
import InfiniteLoading from "vue-infinite-loading";
import VueForceNextTick from "vue-force-next-tick";

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/scss/spinner_colors.scss'


/* Swipper */
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

Vue.component("Swiper", Swiper);
Vue.component("SwiperSlide", SwiperSlide);
Vue.component("Divider", Divider);

/* IconSax */
Vue.component("IconSax", IconSax);

/* Layout */
Vue.component("Layout", Layout);

/* Button */
Vue.component("ButtonPlay", ButtonPlay);

Vue.component(
  "vue-programmatic-invisible-google-recaptcha",
  VueProgrammaticInvisibleGoogleRecaptcha
);

Vue.use(VueGtm, getGtmConfig(router));

Vue.component("ModalUpload", ModalUpload);
Vue.component("ModalUploadPanda", ModalUploadPanda);
Vue.component("BaseButton", Button);
Vue.component("BaseModal", BaseModal);
Vue.component("Modal", Modal);

Vue.component("VoompTube", VoompTube);

Vue.use(InfiniteLoading, {
  props: {
    spinner: "default",
    /* other props need to configure */
  },
  system: {
    throttleLimit: 50,
    /* other settings need to configure */
  }
});

Vue.prototype.$func = func;
Vue.use(VueForceNextTick);
Vue.use(vueVimeoPlayer);
Vue.use(VueObserveVisibility);
Vue.use(VueIntro);
Vue.component("blur", Blur);
Vue.component("my-component-certify", ModalNovoCertificado);
Vue.component("my-certificate-available", ModalCertificateAvailable);
Vue.component("my-issuance-certificate", ModalIssuanceCertificate);


Vue.component("my-component-intro", Intro);
Vue.use(Snackbar);
Vue.use(ToggleButton);
Vue.use(require("vue-moment"));
Vue.use(StarRating);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VueCodeHighlight);
Vue.use(VueSweetalert2);
Vue.use(VueCroppie);
Vue.use(vClickOutside);
Vue.use(Loading, {
    color: 'var(--spinner-color)',
    backgroundColor: 'var(--background-spinner-color)',
    canCancel: false,
    "is-full-page": true
});
library.add(fas);
library.add(fab);
Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
Validator.localize("pt_BR", pt_BR);
Vue.component("font-awesome-icon", FontAwesomeIcon);

export const EventBus = new Vue();

for (let componentKey in components) {
  Vue.component(componentKey, components[componentKey]);
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
