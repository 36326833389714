// getters.js
export default {
  getSettings: (state) => state.settings,
  getIsAdmin: (state) => (state.admin === "owner" ? true : false),
  getIsLoading: (state) => state.loading,
  getMovieCoverMode: (state) => state.settings?.home_vertical,
  getModuleViewMode: (state) => state.settings?.without_category,
  getCategoryViewMode: (state) => !state.settings?.without_category,
  getEnabledCategory: (state) => state.settings?.enable_category,
  getHideDisabledCourses: (state) => state.settings?.hide_courses,
  getModeCarousel: (state) => state.settings?.home_carousel,
  getShowRating: (state) => state.settings?.rating_home,
  getTheme: (state) => state.theme,
  getLinkResearchNPS: (state) => state.linkResearchNPS,
};

