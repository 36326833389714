<template>
    <div class="research">
        <div>
            <div class="research__icon">
                <IconSax name="clipboard-text" />
            </div>
        </div>
        <div :class="isMobile ? 'research__mobile' : 'research__desk'">
            <div class="research__text">
                <p>{{ $t("nps.title_p1") }}</p>
                <p>{{ $t("nps.title_p2") }}</p>
            </div>
            <div class="research__action">
                <a target="_blank" :href="link">{{ $t("nps.text_button") }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isMobile: {
            type: Boolean,
            default: false
        },
        link:{
            type: String,
            required: true
        }
    },
    name: "ResearchNPS"
};
</script>

<style lang="scss" scoped>

.research__mobile{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    .research__action a{
        padding-left: 0;
    }

}
.research__desk{
    display: flex;
}

.research{
    display: flex;
    padding: 10px 36px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}

.research__text p{
    color: var(--fontcolor);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.6px;
    margin: 0;
}

.research__icon, .research__action{
    margin-top: 5px;
}
.research__icon i{
    color: var(--maincolor) !important;
    width: 24px;
    height: 24px;
}

.research__action a{
    color: var(--maincolor);
    padding-left: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
}

</style>