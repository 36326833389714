<template>
  <div class="app" v-if="loaded">
    <div class="box-notificacoes"></div>
    <div class="box-notify"></div>
    <router-view> </router-view>
  </div>
</template>

<script>
import insertGtmScript from "@/utils/externalGtm";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "app",
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
    })
  },
  methods: {
    ...mapActions({
      actionLoad: "app/actionLoad",
      actionSetMobile: "layout/actionSetMobile",
      actionRefreshLayout: "layout/actionRefreshLayout",
      actionFetchSettings: "config/actionFetchSettings",
    }),
    
    handleResize() {
      this.actionSetMobile(window.innerWidth);
      this.actionRefreshLayout();
    },
  },
  async mounted() {
    try {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
      await this.actionLoad();
      if (this.getSettings.google_gtm === true) {
        insertGtmScript(this.getSettings.google_gtm_code)
      }
    } finally {
      this.loaded = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
* {
  font-family: var(--default-font-family);
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.app {
  height: 100%;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: #d6d6d6 !important;
  }

  input[type="range"]::-webkit-slider-thumb {
    background: var(--maincolor) !important;
  }
}

@media screen and (max-width: 768px) {
  .vue-swatches__wrapper {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

.w100 {
  width: 100%;
}

.nao-encontrado {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 30px;
  padding: 20px !important;
  background: #f7f7f7;
  margin: 20px auto;
}

.carregando {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999 !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ring {
  width: 80px;
  height: 80px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 4px 0 rgb(219, 219, 219);
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}

.loader-txt {
  width: 80px;
  height: 80px;
  color: #fff;
  background: transparent;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 5px #262626;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
}

.modoFoco {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.644);
}

.loader-img {
  width: 50px;
  height: 50px;
  justify-content: center;

  img {
    position: relative;
    top: 65px;
    left: 15px;
  }
}

[data-anima="mobile"] {
  animation: op 0.5s forwards;
}

@keyframes op {
  from {
    opacity: 0;
    transform: rotate(180deg);
  }

  to {
    opacity: 1;
    transform: rotate(0);
  }
}

[data-anima="top"] {
  animation: showTop 0.5s forwards;
}

@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="bottom"] {
  animation: showBottom 0.5s forwards;
}

@keyframes showBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="left"] {
  animation: showLeft 0.5s forwards;
}

input,
select,
textarea {
  background: transparent !important;
  color: var(--fontcolor) !important;
  border-color: var(--bordercolor) !important;
}

@keyframes showLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="right"] {
  animation: showRight 0.5s forwards;
}

@keyframes showRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="sidebar"] {
  animation: showSidebar 0.3s forwards;
}

@keyframes showSidebar {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 5px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: var(--default-font-family);
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}

.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}

.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}

.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}

.toast-body {
  color: #5f5c6b !important;
}

.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}

.toast-header strong::before {
  content: "";
  background: url("./assets/icons/notify_default.svg") no-repeat center center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}

// sucesso
.b-toast-success .toast-header strong {
  color: #002363;
}

.b-toast-success .toast-header strong::before {
  background: url("./assets/icons/notify_success.svg") no-repeat center;
}

.b-toast-success .toast {
  background-color: #f2fefa !important;
  border-color: #002363 !important;
  color: #002363 !important;
}

.b-toast-success .toast .toast-header {
  color: #002363 !important;
  background-color: #f2fefa !important;
  border: none !important;
}

.b-toast-success.b-toast-solid .toast {
  background-color: #f2fefa !important;
}

// info
.b-toast-info .toast-header strong {
  color: #002363;
}

.b-toast-info .toast-header strong::before {
  background: url("./assets/icons/notify_info.svg") no-repeat center;
}

.b-toast-info .toast {
  background-color: #f4f5fd !important;
  border-color: #002363 !important;
  color: #002363 !important;
}

.b-toast-info .toast .toast-header {
  color: #002363 !important;
  background-color: #f4f5fd !important;
  border: none !important;
}

.b-toast-info.b-toast-solid .toast {
  background-color: #f4f5fd !important;
}

// danger
.b-toast-danger .toast-header strong {
  color: #ff0c37;
}

.b-toast-danger .toast-header strong::before {
  background: url("./assets/icons/notify_danger.svg") no-repeat center;
}

.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: #ff0c37 !important;
  color: #ff0c37 !important;
}

.b-toast-danger .toast .toast-header {
  color: #ff0c37 !important;
  background-color: #fff3f5 !important;
  border: none !important;
}

.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// warning
.b-toast-warning .toast-header strong {
  color: #ffcb37;
}

.b-toast-warning .toast-header strong::before {
  background: url("./assets/icons/notify_warning.svg") no-repeat center;
}

.b-toast-warning .toast {
  background-color: #fffcf5 !important;
  border-color: #ffcb37 !important;
  color: #ffcb37 !important;
}

.b-toast-warning .toast .toast-header {
  color: #ffcb37 !important;
  background-color: #fffcf5 !important;
  border: none !important;
}

.b-toast-warning.b-toast-solid .toast {
  background-color: #fffcf5 !important;
}

// default
.toast-header strong {
  color: #333;
}

.toast-header strong::before {
  background: url("./assets/icons/notify_default.svg") no-repeat center;
}

.toast {
  background-color: #f6f6f7 !important;
  border-color: #333 !important;
  color: #333 !important;
}

.toast .toast-header {
  color: #333 !important;
  background-color: #f6f6f7 !important;
  border: none !important;
}

.b-toast-solid .toast {
  background-color: #f6f6f7 !important;
}

.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}

.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}

.tooltip-class > .arrow::before  {
  border-top-color: var(--tooltip-background-color)  !important;
}

.tooltip-class > .tooltip-inner {
    max-width: 245px !important;
    color: var(--tooltip-color) !important;
    white-space: nowrap !important;
    border-radius: 4px !important;
    background-color: var(--tooltip-background-color) !important;
}

#modal-crop  .modal-content {
  background: var(--modalcropbackgorund) !important;
  padding: 0 !important;
  width: 696px !important;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.cr-slider{
  cursor: pointer !important;
}


@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
