import router from "@/router/index";

import Cookies from "js-cookie";

/* Services */
import MetaService from "@/services/resources/MetaService";
import MemberService from "@/services/resources/MemberService";
import CertifyService from "@/services/resources/CertifyService";
import EssayService from "@/services/resources/EssayService";

const serviceMeta = MetaService.build();
const serviceMember = MemberService.build();

export default {
  /* Modo de visualização Usuário ou Admin */
  actionUpdateTypeView({ state, commit }) {
    return new Promise((resolve, reject) => {
      const owner =
        Cookies.get("access_level") === "owner" ||
        Cookies.get("access_level") === "admin";

      const role = state.currentSite.currentMember.role;

      if (role === "admin" || role === "owner") {
        if (owner) {
          Cookies.set("access_level", "student");
          Cookies.set("type_student", 1);
          commit("user/hasPermission", true, { root: true });
        } else {
          Cookies.set("access_level", state.currentSite.currentMember.role);
          Cookies.set("type_student", 0);
          commit("user/hasPermission", false, { root: true });
        }
      }

      resolve(Cookies.get("access_level"));
    });
  },
  getHasPermission({ state, commit }) {
    return new Promise((resolve, reject) => {
      const permission = Cookies.get("access_level");
      const typeView = Cookies.get("type_student");

      const isAdminOrOwner = permission === "admin" || permission === "owner";
      const isTypeViewOne = typeView === 1 || typeView === "1";

      if (isAdminOrOwner) {
        commit("user/hasPermission", !isTypeViewOne, { root: true });
        resolve(!isTypeViewOne);
      } else {
        commit("user/hasPermission", false, { root: true });
        reject(false);
      }
    });
  },
  async getCertificateConfig({ commit }) {
    const serviceCertificate = CertifyService.build();
    return new Promise((resolve, reject) => {
      serviceCertificate
        .read("check-exist")
        .then((resp) => {
          commit("user/existConfigCert", resp, { root: true });
          commit("user/certificateConfig", resp, { root: true });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async getEssayPermission({ commit }) {
    const serviceEssay = EssayService.build();
    return serviceEssay
      .read("member")
      .then((resp) => {
        commit("user/hasEssayPermission", resp, { root: true });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getMetasSite: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      serviceMeta
        .search(
          "keys[]=evolucional_allowed&keys[]=study_schedule_allowed&keys[]=exercises_allowed&keys[]=onboarding&keys[]=comment_review&keys[]=helper_data&keys[]=main_color&keys[]=logo&keys[]=theme_fixed_painel&keys[]=theme_fixed_painel_color&keys[]=termsOfUseActivated&keys[]=logomarca"
        )
        .then((resp) => {
          commit("user/metasSite", resp, { root: true });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCurrentSiteAndMember: ({ commit, dispatch }) => {},
  userRequest: async ({ commit, dispatch }) => {
    dispatch("user/redirectUserToHome");
  },
  redirectUserToHome: (context) => {
    router.go(0);
  },

  actionGetMetaSiteScoped({ commit, dispatch }, key) {
    const { currentSite } = this.getters["user/currentSite"];

    if (currentSite) {
      return `site_${currentSite.id}__${key}`;
    }
  },

  async actionFetchMetas({ commit, dispatch }, { keys }) {
    const basePath = ["meta"].join("/");
    const keysPath = keys.map((key) => `keys[]=${key}`).join("&");
    const path = [basePath, keysPath].join("?");
    const metas = await serviceMember.read(path);
    return metas || {};
  },

  async actionLoadMetas({ commit, dispatch }) {
    const { currentSite } = this.getters["user/currentSite"];

    const metas = await dispatch("actionFetchMetas", {
      keys: [
        await dispatch("actionGetMetaSiteScoped", "first_access_optout"),
        `_termos_${currentSite.id}`,
      ],
    });

    commit("SET_METAS", metas);
  },

  async actionSaveMeta({ commit, state }, { key, value }) {
    let data = {
      id: `/meta/${key}`,
      value,
    };
    await serviceMember.postID(data);
    commit("SET_METAS", {
      ...state.metas,
      [key]: value,
    });
  },

  async actionSaveSiteMetas({ dispatch }, { key, value }) {
    await dispatch("actionSaveMeta", {
      key: await dispatch("actionGetMetaSiteScoped", key),
      value,
    });
  },
};
