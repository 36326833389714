<template>
  <div class="user">
    <!-- Button -->
    <a class="user-menu" ref="button" @click="showProfile = !showProfile">
      <div>
        <img v-if="foto_usuario" class="user-photo" :class="{ contains: foto_usuario }" :src="foto_usuario" />
        <div v-else class="user-photo">
          <span>{{ userName.substr(0, 1) }}</span>
        </div>
      </div>
      <IconSax size="1rem" name="arrow-down-1" color="var(--bordercolor)" />
    </a>

    <!-- Popup -->
    <div class="user-popup" v-show="showProfile" v-closable="{
      exclude: ['button'],
      handler: 'onClose',
    }">
      <ul class="user-submenu" :class="{ 'user-submenu-rounded': !showTermsLinks }">
        <li>
          <div class="user-info">
            <div>
              <img v-if="foto_usuario" class="user-photo" :class="{ contains: foto_usuario }" :src="foto_usuario" />
              <div v-else class="user-photo">
                <span>{{ userName.substr(0, 1) }}</span>
              </div>
            </div>

            <div class="user-info-detail">
              <span class="user-info-detail-name">{{ userName }}</span>
              <span class="user-info-detail-email" :class="{ 'has-tooltip': emailMember }" :title="emailMember">
                {{ currentMemberEmail }}
              </span>
            </div>
          </div>
        </li>
        <li @click="showProfile = false">
          <a v-if="hasPermission" @click="updateTypeView">
            <IconSax primary size="1.3rem" name="monitor" />
            {{ $t("menu.logout.ver_como") }} {{ typeView }}
          </a>

          <router-link to="/minha-conta">
            <IconSax primary size="1.3rem" name="profile-circle" />
            {{ $t("menu.profile") }}
          </router-link>

          <router-link v-if="hasPermission && studentCantView" to="/configuracoes">
            <IconSax primary size="1.3rem" name="setting-2" />
            {{ $t("menu.logout.config") }}
          </router-link>

          <router-link to="/my-courses">
            <IconSax primary size="1.3rem" name="video-play" />
            {{ $t("menu.courses") }}
          </router-link>

          <a v-if="currentThemeFixed == 'off' || !currentThemeFixed" @click="toggleTheme">
            <IconSax primary size="1.3rem" :name="toggleThemeIcon" />
            {{ $t(toggleThemeLabel) }}
          </a>

          <a href="#" class="user-logout" @click.prevent="logout">
            <IconSax primary size="1.3rem" name="logout-1" />
            {{ $t("menu.logout.logout2") }}
          </a>
        </li>
      </ul>
      <ul class="user-terms" v-if="showTermsLinks">
        <span class="user-term-span user-terms--item" v-if="showPrivacyPolicy" @click="goToLink(privacyPolicyLink)">
          {{ $t("menu.policy") }}
        </span>
        <svg v-if="showPrivacyPolicy && showUserTerms" xmlns="http://www.w3.org/2000/svg" width="3" height="4"
          viewBox="0 0 3 4" fill="none">
          <circle cx="1.5" cy="2" r="1.5" fill="#404043" />
        </svg>
        <span class="user-term-span user-terms--item" v-if="showUserTerms" @click="goToLink(userTermsLink)">
          {{ $t("modal_termos.title") }}
        </span>
      </ul>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      showProfile: false
    };
  },
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
      getTheme: "config/getTheme",
      hasPermission: "user/hasPermission",
      currentSite: "user/currentSite",
      metasSite: "user/metasSite",
      studentCantView: "user/studentCantView",
      getMe: "auth/getMe",
      getIsAdmin: "config/getIsAdmin",
      getExistConfigCert: "user/getExistConfigCert"
    }),

    typeView() {
      return Cookies.get("type_student") == 0 ? "Aluno" : "Admin";
    },
    currentThemeFixed() {
      return this.metasSite?.theme_fixed_painel || null;
    },
    emailMember() {
      return this.getMe?.current?.currentMember?.email || null;
    },
    currentMemberEmail() {
      const maxLength = 25;
      const email = this.emailMember;

      if (email && email.length >= maxLength) {
        return email.slice(0, maxLength) + "...";
      }

      return email;
    },
    foto_usuario() {
      return this.getMe?.current?.currentMember?.picture || null;
    },
    userName() {
      if (this.getMe?.current?.currentMember?.name) {
        const name = this.getMe?.current?.currentMember?.name.split(" ");
        return name.length > 1 ? `${name[0]} ${name[1]}` : name[0];
      }
      return "";
    },
    toggleThemeIcon() {
      return this.getTheme == "dark" ? "sun-1" : "moon";
    },
    toggleThemeLabel() {
      return this.getTheme == "dark" ? "menu.theme.light" : "menu.theme.dark";
    },
    showTermsLinks() {
      return this.showPrivacyPolicy || this.showUserTerms;
    },
    showPrivacyPolicy() {
      return this.getSettings.link_to_privacy_policy;
    },
    showUserTerms() {
      return this.getSettings.link_to_user_terms;
    },
    privacyPolicyLink() {
      return this.getSettings.link_to_privacy_policy;
    },
    userTermsLink() {
      return this.getSettings.link_to_user_terms;
    },
  },
  methods: {
    ...mapActions({
      actionDefineTheme: "config/actionDefineTheme",
      getHasPermission: "user/getHasPermission",
      actionUpdateTypeView: "user/actionUpdateTypeView",
      logoutRequest: "auth/logoutRequest",
    }),
    onClose() {
      this.showProfile = false;
    },
    goToLink(link) {
      window.open(link, "_blank");
      this.showProfile = false;
    },
    toggleTheme() {
      const theme = this.getTheme == "light" ? "dark" : "light";
      this.actionDefineTheme(theme);
      this.showProfile = false;
    },
    updateTypeView() {
      this.actionUpdateTypeView().then(() => {
        this.$root.$emit("changePermission");
        setTimeout(() => {
          location.reload();
          this.showProfile = false;
        }, 1000);
      });
    },
    logout() {
      this.logoutRequest();
    },
  },
  mounted() {
    this.getHasPermission();
  },
};
</script>

<style lang="scss" scoped>
.user {
  @include flex-row;
  position: relative;
}

.user-menu {
  @include flex-row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--maincolor);
  object-fit: contain;
  display: flex;
  justify-content: center;
}

.user-photo span {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

.user-photo.contains {
  background: none;
}

.user-info {
  @include flex-row;
  align-items: center;
  gap: 0.75rem;
}

.user-info-detail {
  @include flex-column;
  align-items: flex-start;
  gap: 0.125rem;
  color: var(--fontcolor);
}

.user-info-detail-name {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 1.05rem */
}

.user-info-detail-email {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 0.9rem */
}

.has-tooltip {
  cursor: pointer;
}

.user-popup {
  @include flex-column;
  position: absolute;
  right: 0;
  top: 3rem;
  width: 17rem;
  // box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  z-index: 55555 !important;
  animation: showRight 0.3s forwards;
}

/* Sub menu */
.user-submenu {
  @include flex-column;
  align-items: flex-start;
  margin-bottom: 0;
  padding: 1.25rem;
  align-items: flex-start;
  gap: 1.25rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  border: 1px solid var(--sidebar-border);
  background: var(--backgroundcolor);

  &-rounded {
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
  }
}

.user-submenu a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}

.user-submenu a img,
.user-submenu a svg {
  filter: invert(50%);
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 35px;
}

.user-submenu a:hover img,
.user-submenu a:hover svg {
  ::v-deep svg {
    width: 1.25rem;
    height: 1.25rem;

    path {
      stroke: var(--maincolor);
    }
  }
}

a:hover {
  text-decoration: none !important;
}

.user-submenu li a {
  font-weight: 600;
  color: var(--fontcolor);
  font-size: 14px;
  padding: 10px;
  display: flex;
  gap: var(--spacing-16);

  /* background: silver; */
  ::v-deep svg {
    width: 1.25rem;
    height: 1.25rem;

    path {
      fill: var(--maincolor);
    }
  }
}

.user-submenu li a:hover {
  text-decoration: none;
  color: var(--maincolor);
}

.user-submenu li a.router-link-exact-active::before {
  /* display: block;
  position: absolute; */
  left: -15px;
}

.user-submenu li a+a {
  margin-top: 10px;
}

.user-submenu li a img,
.user-submenu li a svg {
  margin-right: 25px;
}

.user-terms {
  display: flex;
  padding: 1.25rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  border-right: 1px solid var(--sidebar-border);
  border-bottom: 1px solid var(--sidebar-border);
  border-left: 1px solid var(--sidebar-border);
  background: var(--backgroundcolor);

  &--item {
    cursor: pointer;
  }
}

.user-term-span {
  color: var(--fontcolor2, #404043);
  /* Body/Regular/10px */
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 0.75rem */
}

.user-logout:hover {
  color: #ff0c37 !important;
}

li::marker {
  color: transparent !important;
}
</style>
