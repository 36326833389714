<template>
  <button data-testid="button-play"
    :style="minWidth ? { minWidth: minWidth } : ''"
    :class="['custom-button', size, type, rounded ? 'rounded' : '', fullWidth ? 'fullWidth' : '', { disabled: disabled }, { 'skeleton skeleton-dark skeleton-button': loading }, $vnode.data.staticClass]"
    @click="emitClick" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    minWidth: {
      type: String,
    },
    type: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "outline" | "normal" | "clear",
      default: "outline",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid var(--maincolor);
  border-radius: var(--default-radius);

  ::v-deep svg {
    width: 1rem;
    height: 1rem;
  }

  &.normal {
    color: #ffffff;
    background-color: var(--maincolor);

    ::v-deep svg path {
      fill: var(--neutral-white) !important;
    }

    &:hover {
      ::v-deep svg path {
        fill: var(--neutral-white) !important;
      }

      .isax, i {
        color: var(--neutral-white) !important;
      }
    }
  }

  &.outline {
    color: var(--maincolor);
    background: none;

    ::v-deep .svgCss {
      svg path {
        fill: var(--maincolor) !important;
      }
    }

    .isax, i {
      color: var(--maincolor) !important;
    }

    &:hover {
      ::v-deep .svgCss {
        svg path {
          fill: #fff !important;
        }
      }

      .isax, i {
        color: var(--neutral-white) !important;
      }
    }
  }

  &.clear {
    border: none;
  }

  &.rounded {
    border-radius: 999px !important;
  }

  &.gray {
    color: var(--neutral-gray-400);
    background: none;
    border: 1px solid var(--neutral-gray-400);

    ::v-deep svg path {
      fill: var(--neutral-gray-400);
    }

    .isax, i {
      color: var(--neutral-gray-400) !important;
    }

    &:hover {
      border: 1px solid var(--maincolor);

      ::v-deep svg path {
        fill: var(--neutral-white);
      }

      .isax, i {
        color: var(--neutral-white) !important;
      }
    }
  }
  &.feedback-error {
    color: var(--feedback-error-600);
    background: none;
    border: 1px solid var(--feedback-error-600);

    ::v-deep svg path {
      fill: var(--feedback-error-600);
    }

    &:hover {
      border: 1px solid var(--feedback-error-600);
      color: var(--neutral-white);
      ::v-deep svg path {
        fill: var(--feedback-error-600);
      }
    }
  }

  &.smm {
    font: var(--body-semibold-12);
    height: 32px;
    padding: 0px 12px;
  }

  &.sm {
    font: var(--body-semibold-14);
    height: 32px;
    padding: 0px 12px;
  }

  &.lg {
    font: var(--body-semibold-16);
    height: 48px;
    padding: 0px 12px;
  }

  &:hover {
    background-color: var(--maincolor);
    color: #ffffff;

    ::v-deep svg path {
      fill: var(--neutral-white);
    }
  }

  &:active,
  &.active {
    background-color: var(--maincolor);
    color: #ffffff;

    ::v-deep svg path {
      fill: var(--neutral-white);
    }
  }

  &.disabled {
    cursor: not-allowed;

    i, .isax {
      color: var(--neutral-gray-600) !important;
    }
  }

  &.normal.disabled {
    background-color: var(--neutral-gray-200);
    color: var(--neutral-gray-600);
    border-color: var(--neutral-gray-200);
  }

  &.outline.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: transparent;
    color: var(--neutral-gray-600);
    border-color: var(--neutral-gray-200);
  }

  &.fullWidth {
    width: 100%;
    justify-content: flex-start;
    text-align: left;
  }

}
</style>
