<template>
  <div class="example-full">
    <!-- <button type="button" class="btn btn-danger float-right btn-is-option" @click.prevent="isOption = !isOption">
    <i class="fa fa-cog" aria-hidden="true"></i>
    Opções
  </button> -->
    <!-- <h1 id="example-title" class="example-title">Upload de Arquivos</h1> -->

    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <!-- <h3>Drop files to upload</h3> -->
    </div>
    <div class="upload" v-show="!isOption">
      <div class="table-responsive">
        <table class="table table-hover">
          <tbody :class="{ displayImages: files.length }">
            <tr v-if="!files.length">
              <td colspan="7">
                <div class="newTextDiv">
                  Tamanho máximo permitido: {{ sizex }}.
                </div>
                <div class="text-center p-5">
                  <h4 class="newText">
                    Solte seus arquivos aqui<br />
                    <div class="newTextDiv">ou</div>
                  </h4>
                  <label :for="name" class="btn btn-lg newBtn">Selecionar Arquivos</label>
                </div>
              </td>
            </tr>
            <tr v-for="(file, index) in files" :key="file.id">
              <div>
                <div v-if="file.thumb && file.type !== 'audio/mpeg'" class="">
                  <img class="td-image-thumb" v-if="file.thumb" :src="file.thumb" />
                  <!-- <span @click.prevent="$refs.upload.remove(file)" class="removeX">X</span> -->
                  <div class="filename nameUploaded" :index="index">
                    {{ file.name }}
                  </div>
                  <div class="progress" v-if="file.active || file.progress !== '0.00'">
                    <div :class="{
      'progress-bar': true,
      'progress-bar-striped': true,
      'bg-danger': file.error,
      'progress-bar-animated': file.active,
    }" role="progressbar" :style="{ width: file.progress + '%' }">
                      {{ file.progress }}%
                    </div>
                  </div>
                </div>
                <div v-else-if="file.type === 'audio/mpeg'">
                  <img class="td-image-thumb" src="@/assets/img/audio.png" />
                  <div class="filename nameUploaded" :index="index">
                    {{ file.name }}
                  </div>
                  <div class="progress" v-if="file.active || file.progress !== '0.00'">
                    <div :class="{
      'progress-bar': true,
      'progress-bar-striped': true,
      'bg-danger': file.error,
      'progress-bar-animated': file.active,
    }" role="progressbar" :style="{ width: file.progress + '%' }">
                      {{ file.progress }}%
                    </div>
                  </div>
                </div>
                <div v-else>
                  <img class="td-image-thumb" src="@/assets/img/semimagem.jpg" />
                  <!-- <span @click.prevent="$refs.upload.remove(file)" class="removeX">X</span> -->
                  <div class="filename nameUploaded" :index="index">
                    {{ file.name }}
                  </div>
                  <div class="progress" v-if="file.active || file.progress !== '0.00'">
                    <div :class="{
      'progress-bar': true,
      'progress-bar-striped': true,
      'bg-danger': file.error,
      'progress-bar-animated': file.active,
    }" role="progressbar" :style="{ width: file.progress + '%' }">
                      {{ file.progress }}%
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="marginTopBtn">
        <label v-if="files.length" :for="name" class="btn btn-lg newBtn">Selecionar Arquivos</label>
      </div>
      <div class="example-foorer">
        <!-- <div class="footer-status float-right">
        Drop: {{$refs.upload ? $refs.upload.drop : false}},
        Active: {{$refs.upload ? $refs.upload.active : false}},
        Uploaded: {{$refs.upload ? $refs.upload.uploaded : true}},
        Drop active: {{$refs.upload ? $refs.upload.dropActive : false}}
      </div> -->
        <div class="btn-group">
          <file-upload class="btn btn-primary dropdown-toggle" :post-action="postAction" :extensions="extensions"
            :multiple="multiple" :directory="directory" :headers="headers" :create-directory="createDirectory"
            :size="size || 0" :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread" :data="data" :drop="drop"
            :drop-directory="dropDirectory" :add-index="addIndex" v-model="files" @input-filter="inputFilter"
            @input-file="inputFile" ref="upload">
            <i class="fa fa-plus"></i>
            Selecionar
          </file-upload>
          <div class="dropdown-menu">
            <label class="dropdown-item" :for="name">Adicionar Arquivos</label>
            <a class="dropdown-item" href="#" @click="onAddFolder">Adicionar Pasta</a>
            <a class="dropdown-item" href="#" @click.prevent="addData.show = true">Criar Documento</a>
          </div>
        </div>
        <button type="button" class="btn btn-success" v-if="!$refs.upload || !$refs.upload.active"
          @click.prevent="$refs.upload.active = true" v-on:click="submitxx">
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Fazer Upload
        </button>
        <button type="button" class="btn btn-danger" v-else @click.prevent="$refs.upload.active = false">
          <i class="fa fa-stop" aria-hidden="true"></i>
          Pausar Upload
        </button>
      </div>
    </div>

    <div class="option" v-show="isOption">
      <div class="form-group">
        <label for="accept">Accept:</label>
        <input type="text" id="accept" class="form-control" v-model="accept" />
        <small class="form-text text-muted">Allow upload mime type</small>
      </div>
      <div class="form-group">
        <label for="extensions">Extensions:</label>
        <input type="text" id="extensions" class="form-control" v-model="extensions" />
        <small class="form-text text-muted">Allow upload file extension</small>
      </div>
      <div class="form-group">
        <label>PUT Upload:</label>
        <!-- <div class="form-check">
        <label class="form-check-label">
          <input class="form-check-input" type="radio" name="put-action" id="put-action" value="" v-model="putAction"> Off
        </label>
      </div> -->
        <!-- <div class="form-check">
        <label class="form-check-label">
          <input class="form-check-input" type="radio" name="put-action" id="put-action" value="/upload/put" v-model="putAction"> On
        </label>
      </div> -->
        <small class="form-text text-muted">After the shutdown, use the POST method to upload</small>
      </div>
      <div class="form-group">
        <label for="thread">Thread:</label>
        <input type="number" max="5" min="1" id="thread" class="form-control" v-model.number="thread" />
        <small class="form-text text-muted">Also upload the number of files at the same time (number of
          threads)</small>
      </div>
      <div class="form-group">
        <label for="size">Max size:</label>
        <input type="number" min="0" id="size" class="form-control" v-model.number="size" />
      </div>
      <div class="form-group">
        <label for="minSize">Min size:</label>
        <input type="number" min="0" id="minSize" class="form-control" v-model.number="minSize" />
      </div>
      <div class="form-group">
        <label for="autoCompress">Automatically compress:</label>
        <input type="number" min="0" id="autoCompress" class="form-control" v-model.number="autoCompress" />
        <small class="form-text text-muted" v-if="autoCompress > 0">More than {{ $formatSize(autoCompress) }} files are
          automatically
          compressed</small>
        <small class="form-text text-muted" v-else>Set up automatic compression</small>
      </div>

      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" id="add-index" class="form-check-input" v-model="addIndex" />
            Start position to add
          </label>
        </div>
        <small class="form-text text-muted">Add a file list to start the location to add</small>
      </div>

      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" id="drop" class="form-check-input" v-model="drop" />
            Drop
          </label>
        </div>
        <small class="form-text text-muted">Drag and drop upload</small>
      </div>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" id="drop-directory" class="form-check-input" v-model="dropDirectory" />
            Drop directory
          </label>
        </div>
        <small class="form-text text-muted">Not checked, filter the dragged folder</small>
      </div>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" id="create-directory" class="form-check-input" v-model="createDirectory" />
            Create Directory
          </label>
        </div>
        <small class="form-text text-muted">The directory file will send an upload request. The mime type is
          <code>text/directory</code></small>
      </div>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" id="upload-auto" class="form-check-input" v-model="uploadAuto" />
            Auto start
          </label>
        </div>
        <small class="form-text text-muted">Automatically activate upload</small>
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-primary btn-lg btn-block" @click.prevent="isOption = !isOption">
          Confirm
        </button>
      </div>
    </div>

    <div :class="{
      'modal-backdrop': addData.show,
      fade: !addData.show,
      show: addData.show,
    }"></div>
    <div :class="{
      modal: !addData.show,
      modal2: addData.show,
      fade: !addData.show,
      show: addData.show,
    }" id="modal-add-data" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Adicionar Arquivo de Texto</h5>
            <button type="button" class="close" @click.prevent="addData.show = false">
              <span>&times;</span>
            </button>
          </div>
          <form @submit.prevent="onAddData">
            <div class="modal-body">
              <div class="form-group">
                <label for="data-name">Nome:</label>
                <input type="text" class="form-control" required id="data-name" placeholder="Nome do Arquivo"
                  v-model="addData.name" />
                <small class="form-text text-muted">Such as <code>nomedoarquivo.txt</code></small>
              </div>
              <div class="form-group">
                <label for="data-type">Tipo:</label>
                <input type="text" class="form-control" required id="data-type" placeholder="Tipo do Arquivo"
                  v-model="addData.type" />
                <small class="form-text text-muted">Such as <code>text/plain</code></small>
              </div>
              <div class="form-group">
                <label for="content">Conteudo:</label>
                <textarea class="form-control" required id="content" rows="3" placeholder="Conteudo do Arquivo"
                  v-model="addData.content"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click.prevent="addData.show = false">
                Cancelar
              </button>
              <button type="submit" class="btn btn-primary">
                Salvar Arquivo
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div :class="{
      'modal-backdrop': editFile.show,
      fade: !editFile.show,
      show: editFile.show,
    }"></div>
    <div :class="{
      modal: !editFile.show,
      modal2: editFile.show,
      fade: !editFile.show,
      show: editFile.show,
    }" id="modal-edit-file" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Editar Arquivo</h5>
            <button type="button" class="close" @click.prevent="editFile.show = false">
              <span>&times;</span>
            </button>
          </div>
          <form @submit.prevent="onEditorFile">
            <div class="modal-body">
              <div class="form-group">
                <label for="name">Nome:</label>
                <input type="text" class="form-control" required id="name" placeholder="Nome do Arquivo"
                  v-model="editFile.name" />
              </div>
              <div class="form-group" v-if="editFile.show &&
      editFile.blob &&
      editFile.type &&
      editFile.type.substr(0, 6) === 'image/'
      ">
                <label>Image: </label>
                <div class="edit-image">
                  <img :src="editFile.blob" ref="editImage" />
                </div>

                <div class="edit-image-tool">
                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-primary" @click="editFile.cropper.rotate(-90)"
                      title="cropper.rotate(-90)">
                      <i class="fa fa-undo" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-primary" @click="editFile.cropper.rotate(90)"
                      title="cropper.rotate(90)">
                      <i class="fa fa-repeat" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-primary" @click="editFile.cropper.crop()"
                      title="cropper.crop()">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btn btn-primary" @click="editFile.cropper.clear()"
                      title="cropper.clear()">
                      <i class="fa fa-remove" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click.prevent="editFile.show = false">
                Cancelar
              </button>
              <button type="submit" class="btn btn-primary">Salvar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from "../../node_modules/cropperjs";
import ImageCompressor from "../../node_modules/@xkeshi/image-compressor";
import FileUpload from "../../node_modules/vue-upload-component";
import Cookies from "js-cookie";
import notify from "@/services/libs/notificacao";

export default {
  props: {
    sizex: {
      type: String,
      required: true
    },
    postAction: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    acceptedFileExtensions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      files: [],
      accept:
        "audio/mpeg,video/mp4,xlsx,application/msword,xls,doc,docx,image/png,image/gif,image/jpeg,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/webp/pdf,application/zip,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documentl.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-rar,application/x-rar-compressed",
      extensions:
        "pptx,ppt,mp3,gif,jpg,jpeg,png,webp,mp4,xlsx,pdf,zip,xls,doc,docx,rar",
      minSize: 1024,
      size: 4 * 1024 * 1024 * 1024, // 2 GB
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      name: "file",
      headers: {
        Authorization: "xxx",
      },
      data: {
        _csrf_token: "xxxxxx",
      },
      autoCompress: 2 * 1024 * 1024 * 1024, // 2 GB
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: "",
        type: "",
        content: "",
      },
      editFile: {
        show: false,
        name: "",
      },
    };
  },
  mounted() {
    this.getAuth();
  },
  computed: {},
  watch: {
    "editFile.show"(newValue, oldValue) {
      // Erro fechado e excluído automaticamente
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || "",
        });
      }
      if (newValue) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return;
          }
          let cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false,
          });
          this.editFile = {
            ...this.editFile,
            cropper,
          };
        });
      }
    },
    "addData.show"(show) {
      if (show) {
        this.addData.name = "";
        this.addData.type = "";
        this.addData.content = "";
      }
    },
  },
  methods: {
    submitxx() { },
    getAuth() {
      this.headers.Authorization = Cookies.get("auth_greennCourse");
    },
    $formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
    checkFileExtension(fileName) {
      if(this.acceptedFileExtensions.length <= 0 ){
        return true;
      }

      const fileExtension = fileName.split('.').pop().toLowerCase();
      return this.acceptedFileExtensions.includes(fileExtension);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {

        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }

        if (!this.checkFileExtension(newFile.name)) {
          notify("erro", this.$t('certificates_component.unsupported_format', { extensions: this.acceptedFileExtensions.join('/').toUpperCase() }));
          return prevent();
        }

        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          !newFile.file.name.endsWith(".mp4") &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing";

          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512,
          });

          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type,
              });
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {
                error: err.message || "compress",
              });
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field

        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails

        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
          notify('erro', this.$t('certificates_component.file_error', { filename: newFile.name }))
        }
        if (newFile.success && !oldFile.success) {
          // success
          var itens = this.files.length;

          if (itens === 1) {
            this.$root.$emit("midiaUploadedSucess", {
              file: newFile,
              id: this.id
            });
          }
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      this.$refs.upload.active = true;
    },
    alert(message) {
      alert(message);
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.$refs.upload.update(file, { error: "edit" });
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support");
        this.editFile.show = false;
        return;
      }
      let data = {
        name: this.editFile.name,
      };
      if (this.editFile.cropper) {
        let binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(",")[1]
        );
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, { type: this.editFile.type });
        data.size = data.file.size;
      }

      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = "";
      this.editFile.show = false;
    },
    // add folder
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert("Your browser does not support");
        return;
      }
      let input = document.createElement("input");
      input.style =
        "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;";
      input.type = "file";
      input.setAttribute("allowdirs", true);
      input.setAttribute("directory", true);
      input.setAttribute("webkitdirectory", true);
      input.multiple = true;
      document.querySelector("body").appendChild(input);
      input.click();
      input.onchange = () => {
        this.$refs.upload.addInputFile(input).then(function () {
          document.querySelector("body").removeChild(input);
        });
      };
    },
    onAddData() {
      this.addData.show = false;
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support");
        return;
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type,
      });

      this.$refs.upload.add(file);
    },
  },
};
</script>

<style scoped>
.table-responsive {
  overflow: auto;
  margin-top: -30px;
  min-height: 9em;
}

.edit-image-tool {
  display: none;
}

.example-full {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.modal2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}

.example-full .btn-group:hover>.dropdown-menu {
  visibility: visible;
}

.example-full label.dropdown-item {
  margin-bottom: 0;
}

.marginTopBtn {
  margin-top: 10px;
}

.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}

.example-full .filename {
  margin-bottom: 0.3rem;
}

.filename {
  white-space: nowrap;
  width: 80%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  margin-top: 3px;
}

.example-full .btn-is-option {
  margin-top: 0.25rem;
}

.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}

.example-full .edit-image img {
  max-width: 30%;
}

.example-full .edit-image-tool {
  margin-top: 0.6rem;
}

.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}

.example-full .footer-status {
  padding-top: 0.4rem;
}

.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.removeX {
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000;
  position: relative;
  top: -24px;
  left: -12px;
}

.example-full .example-foorer {
  display: none !important;
}

.newText {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--fontcolor);
}

.newTextDiv {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--fontcolor);
  margin-top: 10px;
  margin-bottom: 10px;
}

.table th,
.table td {
  border-top: 1px solid #ffffff;
}

tbody.displayImages {
  display: grid;
  grid-template-columns: repeat(auto-fit, 190px);
  gap: 12px;
  height: 43vh;
  overflow: auto;
}

.displayImages tr,
.displayImages tr div {
  width: 100%;
}

.displayImages tr div div {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.displayImages tr div div img {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.newBtn {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  height: 55px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--maincolor);
  padding-top: 17px;
  width: 292.85px;
}

.table-hover tbody tr:hover {
  color: #333 !important;
  background-color: #fff0 !important;
}

.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
